
import './style.scss';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, DialogActions, Typography, Divider, Button, ImageList, Radio, FormControl, RadioGroup, FormControlLabel } from '@mui/material';
import { Check, PhotoCamera } from '@mui/icons-material';
import FormHelper from '../../helpers/FormHelper';
import ExtraScoresService from '../../services/ExtraScoresService';
import AttachmentCard from '../AttachmentCard/AttachmentCard';
import useForceRender from '../../hooks/useForceRender';


const DialogExtraScores = ({
    isOpen,
    setClosedDialog = () => {},
    token,
    storeCode,
    category,
    extraScore = {},
    readonly = false,
    handleRefresh,
    setLoading,
    setError
}) => {
    const forceRender = useForceRender();

    const [arquivos, setArquivos] = useState([]);
    const uploadInputRef = useRef(null);

    const title = `Pontuações Extras / ${extraScore.category || ''}`.toUpperCase();
    const fileUploaded = arquivos && arquivos.length > 0;


    useEffect(() => {
        setArquivos([]);
    }, [extraScore]);

    const onSubmit = async (e) => {
        e.preventDefault();

        if (readonly) {
            return;
        }

        if (fileUploaded) {
            setLoading(true);

            let formData = FormHelper.GenerateFormData(e, null);
            formData = await FormHelper.IncludeFileList(formData, 'attachments', arquivos);

            ExtraScoresService.Done(token, extraScore.id, storeCode, category, formData)
                .then(resp => {
                    setError(`Card finalizado com sucesso`, 'success');
                    setClosedDialog();
                    handleRefresh();
                }).catch(err => {
                    setError('Não foi possível finalizar o card');
                }).finally(() => {
                    setLoading(false);
                });
        } else {
            uploadInputRef.current && uploadInputRef.current.click();
        }
    }


    const handleAddAttach = (e) => {
        const arquivosAux = arquivos;

        Object.entries(e.target.files).forEach(([key, file]) => {
            arquivosAux.push(file);
        });

        setArquivos(arquivosAux);
        forceRender();
    }

    const handleRemoveAttach = (key) => {
        const arquivosAux = Array.from(arquivos);
        arquivosAux.splice(key, 1);

        setArquivos(arquivosAux);
        forceRender();
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth='xs'
            fullWidth={true}
            onClose={setClosedDialog}
            PaperProps={{
                className: `dialog-default dialog-extrascores`
            }}
        >
            <Box component='form' onSubmit={onSubmit}>
                <DialogTitle className='dialogtitle-default'>
                    <Typography variant='subtitle2' className='color-white'>
                        {title}
                    </Typography>
                </DialogTitle>
                
                <DialogContent className='dialogcontent-default'>
                    <input
                        ref={uploadInputRef}
                        type="file"
                        style={{
                            display: 'none'
                        }}
                        multiple={true}
                        value=''
                        onChange={handleAddAttach}
                    />

                    <Typography variant='h6' className='fontweight-600' lineHeight={1}>
                        {extraScore.title || ''}
                    </Typography>

                    <Box paddingY={3}>
                        <Typography variant='body2'>
                            <span className='fontweight-600'>Descritivo: </span>
                            
                            {
                                (extraScore.description || '').split('\n').map((text, index) => {
                                    if (index == 0) {
                                        return <span
                                            key={index}
                                            dangerouslySetInnerHTML={{ __html: text }}
                                        />;
                                    }

                                    return <span
                                        key={index}
                                        style={{
                                            display: 'block',
                                            width: '100%',
                                            marginTop: '8px'
                                        }}
                                        dangerouslySetInnerHTML={{ __html: text }}
                                    />;
                                })
                            }
                        </Typography>
                    </Box>

                    {
                        extraScore.image && (
                            <Box>
                                <ImageList cols={1}>
                                    <AttachmentCard 
                                        url={extraScore.image} 
                                        name={extraScore.image}
                                        onClick={() => window.open(extraScore.image)} 
                                        showPreviewIcon={true}
                                    />
                                </ImageList>
                            </Box>
                        )
                    }

                    <Box>
                        <ImageList cols={3}>
                            {
                                arquivos.map((arquivo, index) => {
                                    const url = URL.createObjectURL(arquivo);

                                    return <AttachmentCard 
                                        url={url} 
                                        name={arquivo.name} 
                                        onRemove={_ => handleRemoveAttach(index)}
                                        key={index}
                                    />;
                                })
                            }
                        </ImageList>
                    </Box>

                    <Divider />

                    {[1, 5].includes(extraScore.status) && extraScore.allow_question == "S" && !readonly && (
                        <>
                            <Box>
                                <Typography variant='subtitle1' className="fontweight-600 mt-3">
                                    O comprovante anexado será válido para:
                                </Typography>

                                <FormControl style={{ marginLeft: '6px', marginTop: '8px' }}>
                                    <RadioGroup defaultValue="P" name="question">
                                        <FormControlLabel
                                            value="P"
                                            control={<Radio size="small" style={{ padding: "3px" }} />}
                                            label="Só para esta participante"
                                        />
                                        <FormControlLabel
                                            value="T"
                                            control={<Radio size="small" style={{ padding: "3px" }} />}
                                            label="Todos os códigos vinculados a este usuário*"
                                        />
                                    </RadioGroup>
                                </FormControl>

                                <Typography fontSize={12} className="mt-1">
                                    <b style={{ color: "#e32528" }}>Atenção!</b> Se marcar a segunda opção, o comprovante anexado servirá automaticamente para <b>todas as participantes vinculadas ao seu usuário que compartilham a MESMA CATEGORIA E MESMO CÓDIGO AVÔ</b>.
                                </Typography>
                                <Typography fontSize={12} className="mt-1">
                                    Só selecione a segunda opção se o comprovante dessa tarefa também valer para as demais revendas ou recapadoras do grupo. <b>Comprovantes errados serão invalidados</b> pelo seu assessor e/ou na auditoria.
                                </Typography>
                            </Box>

                            <Divider className="mt-3" />
                        </>
                    )}
                </DialogContent>
                
                <DialogActions className='dialogactions-default'>
                    {
                        readonly ? (
                            <Button
                                type='button'
                                className='background-grey-4 color-white borderradius-default'
                                size='small'
                                onClick={setClosedDialog}
                                sx={{
                                    marginX: 'auto'
                                }}
                            >
                                Fechar
                            </Button>
                        ) : (
                            <>
                                <Button
                                    type='button'
                                    className={`color-white borderradius-default ${fileUploaded ? 'background-green' : 'background-red'}`}
                                    size='small'
                                    startIcon={fileUploaded ? <Check fontSize='small' /> : <PhotoCamera fontSize='small' />}
                                    onClick={_ => uploadInputRef.current && uploadInputRef.current.click()}
                                >
                                    {fileUploaded ? `${arquivos.length} arquivos anexados` : 'Anexar comprovante'}
                                </Button>
                                
                                <Button
                                    type='submit'
                                    className='background-green color-white borderradius-default'
                                    size='small'
                                >
                                    Enviar para análise
                                </Button>
                            </>
                        )
                    }
                </DialogActions>
            </Box>
        </Dialog>
    );
}

export default DialogExtraScores;